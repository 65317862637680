import {Ajax} from '../utils/index';

export default {
  saveUser(data) {
    let promise = Ajax.postPromise('/Account/save', data);
    return promise;
  },
  login(data) {
    let promise = Ajax.postPromise('/Account/login', data);
    return promise;
  },
  googleLogin(data) {
    let promise = Ajax.postPromise('/Account/google-login', data);
    return promise;
  },
  resetPassword(data) {
    let promise = Ajax.postPromise('/Account/password-reset', data);
    return promise;
  },
  changePassword(data) {
    let promise = Ajax.postPromise('/Account/change-password-forgetten', data);
    return promise;
  },
  verifyAccount(data) {
    let promise = Ajax.getPromise(data);
    return promise;
  },
  sendContactUs(data) {
    let promise = Ajax.postPromise('/Account/contact-us', data);
    return promise;
  },
};
