import { Ajax } from '../utils/index';

export default {
  getMeetings (data) {
    let promise = Ajax.postPromise('/meeting/get-meetings', data);
    return promise;
  },
  getAllMeetingRecords (data) {
    let promise = Ajax.postPromise('/meeting/get-meetings-for-recordings', data);
    return promise;
  },
  addOrUpdateMeeting (data) {
    let promise = Ajax.postPromise('/meeting/create-meeting-v2', data);
    return promise;
  },
  editMeeting (data) {
    let promise = Ajax.postPromise('/meeting/edit-meeting', data);
    return promise;
  },
  createQuicklyMeeting (meetingName) {
    let promise = Ajax.getPromise('/meeting/create-quickly-meeting/' + meetingName);
    return promise;
  },
  joinMeeting (data) {
    let promise = Ajax.postPromise('/meeting/join', data);
    return promise;
  },
  suspendMeeting (meetingId) {
    let promise = Ajax.getPromise('/meeting/suspend-meeting/' + meetingId);
    return promise;
  },
  getMyCalls () {
    let promise = Ajax.getPromise('meeting/get-my-calls');
    return promise;
  }
};
