import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import {MeetingServices} from '../../../../../../services';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import * as yup from 'yup';
import moment from 'moment';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import TimeTextField from '../../../../../../common/TimeTextField';
import Typography from '@mui/material/Typography';
import userServices from '../../../../../../services/userServices';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import {FormControlLabel} from '@mui/material';

const filter = createFilterOptions();

yup.addMethod(yup.date, 'format', function (formats, parseStrict) {
	return this.transform(function (value, originalValue) {
		value = moment(originalValue, formats, parseStrict);
		return value.isValid() ? value.toDate() : new Date('');
	});
});

yup.addMethod(yup.string, 'mName', function (errorMessage) {
	return this.test('test-mName', errorMessage, function (value) {
		const {path, createError} = this;
		
		if (value !== undefined) {
			if (value.indexOf('/*/*') > -1) return false;
		}
		
		return true || createError({path, message: errorMessage});
	});
});

const AddOrUpdateMeetingForm = ({setVisibleModal, getMeetings, meetingTitle = '', userIds = [], selectedMeeting}) => {
	const {t} = useTranslation();
	const now = moment();
	const strTime = now.clone().startOf('hour').add(1, 'hour').toDate();
	const endTime = now.clone().startOf('hour').add(2, 'hour').toDate();
	const initialValues = {
		name: selectedMeeting?.title || '',
		userIds: selectedMeeting?.meetingUsers || [],
		startingDate: selectedMeeting?.start ? moment(selectedMeeting.start).toDate() : strTime,
		endDate: selectedMeeting?.end ? moment(selectedMeeting.end).toDate() : endTime,
		description: selectedMeeting?.body || '',
		meetingId: selectedMeeting?.meetingId || '',
		parentId: selectedMeeting?.parentId || '',
		recurrenceType: selectedMeeting?.recurrenceType || 'none',
		isRecurring: selectedMeeting?.recurrenceType && selectedMeeting.recurrenceType !== 'none'
	};
	const [buttonIsLoading, setButtonIsLoading] = useState(false);
	const [isRecurring, setIsRecurring] = useState(selectedMeeting?.recurrenceType ? selectedMeeting.recurrenceType !== 'none' : false);
	const [recurrenceType, setRecurrenceType] = useState(selectedMeeting?.recurrenceType || 'none');
	const [selectedDays, setSelectedDays] = useState([]);
	const [addressBook, setAddressBook] = useState([]);
	
	useEffect(() => {
		const fetchAddressBook = async () => {
			try {
				const [addressBookResponse, companyUsersResponse] = await Promise.all([
					userServices.getAddressbook(),
					userServices.getAllCompanyUsers()
				]);
				
				const updatedAddressBook = addressBookResponse.data.map(user => ({
					...user,
					name: user.name || 'No Name' // Varsayılan isim atama
				}));
				
				const updatedCompanyUsers = companyUsersResponse.data.map(user => ({
					...user,
					name: user.fullName || 'No Name' // Varsayılan isim atama
				}));
				
				setAddressBook([...updatedAddressBook, ...updatedCompanyUsers]);
			} catch (error) {
				console.error('Error fetching address book or company users:', error);
			}
		};
		fetchAddressBook();
	}, []);
	
	useEffect(() => {
		if (selectedMeeting) {
			console.log(selectedMeeting);
			const daysArray = selectedMeeting.selectedDays ? selectedMeeting.selectedDays.split(',').map(Number) : [];
			formik.setValues({
				name: selectedMeeting.title || '',
				userIds: selectedMeeting.meetingUsers || [],
				selectedDays: daysArray,
				startingDate: moment(selectedMeeting.start),
				endDate: moment(selectedMeeting.end),
				description: selectedMeeting.body || '',
				meetingId: selectedMeeting.meetingId || '',
				parentId: selectedMeeting.parentId || '',
				recurrenceType: selectedMeeting.recurrenceType || 'none',
				isRecurring: selectedMeeting.recurrenceType && selectedMeeting.recurrenceType !== 'none'
			});
			
			setSelectedDays(daysArray.map(day => ({
				label: t(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][day]),
				value: day
			})));
			setIsRecurring(selectedMeeting.recurrenceType && selectedMeeting.recurrenceType !== 'none');
			setRecurrenceType(selectedMeeting.recurrenceType || 'none');
		}
	}, [selectedMeeting]);
	
	
	const validationSchema = yup.object({
		name: yup
			.string(t('requiredMeetingTitle'))
			.min(2, t('minLengthMeetingTitle'))
			.trim()
			.required(t('requiredMeetingTitle'))
			.mName(t('validMeetingTitle')),
		userIds: yup
			.array()
			.of(yup.object())
			.min(1, t('requiredAddParticipant')),
		startingDate: yup
			.date()
			.required(t('validDate'))
			.min(new Date(), t('startDateCannotBePast'))
			.nullable(false),
		endDate: yup
			.date()
			.required(t('validDate'))
			.min(
				yup.ref('startingDate'),
				t('endDateCannotBeBeforeStartDate')
			)
			.nullable(false)
	});
	
	const ValidateEmail = (email) => {
		const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if (!validRegex.test(email)) {
			Swal.fire({
				icon: 'error',
				title: t('validEmail'),
				text: t('invalidEmailMessage')
			});
			return false;
		}
		return true;
	};
	
	const onSubmit = async (values) => {
		setButtonIsLoading(true);
		
		const parsedSelectedDays = typeof values.selectedDays === "string" ? values.selectedDays.split(",").map(Number) : values.selectedDays || [];
		
		const payload = {
			...values,
			startingDate: moment(values.startingDate).local().format("YYYY-MM-DDTHH:mm"),
			endDate: moment(values.endDate).local().format("YYYY-MM-DDTHH:mm"),
			recurrenceType: isRecurring ? recurrenceType : "none",
			selectedDays: parsedSelectedDays,
			meetingId: selectedMeeting ? selectedMeeting.meetingId : undefined,
		};
		
		try {
			const response = await MeetingServices.addOrUpdateMeeting(payload);
			Swal.fire({
				icon: 'success',
				title: t('success'),
				text: t(selectedMeeting ? 'successEditMeeting' : 'successPlanMeeting'),
				confirmButtonText: t('ok')
			});
			setVisibleModal(false);
			if (getMeetings) getMeetings();
		} catch (error) {
			Swal.fire({
				icon: 'error',
				title: t('error'),
				text: t('errorOccurred')
			});
		} finally {
			setButtonIsLoading(false);
		}
	};
	
	const formik = useFormik({
		initialValues,
		validateOnChange: true,
		validateOnBlur: true,
		validationSchema: validationSchema,
		onSubmit
	});
	
	useEffect(() => {
		console.log(formik.errors);
	}, [formik.errors]);
	
	const isButtonDisabled = !formik.values.name ||
		!formik.values.userIds.length ||
		(!isRecurring && (!formik.values.startingDate || !formik.values.endDate)) ||
		(isRecurring &&
			((recurrenceType === 'daily' && (!formik.values.startingDate || !formik.values.endDate)) ||
				(recurrenceType === 'weekly' && (!selectedDays.length || !formik.values.startingDate || !formik.values.endDate))));
	
	return (
		<Box sx={{marginBottom: '40px'}}>
			<Box marginBottom={3} sx={{textAlign: 'center'}}>
				<Typography
					variant="h5"
					color="text.tertiary"
					sx={{
						fontWeight: 700,
						textAlign: 'center'
					}}
				>
					{t(selectedMeeting ? 'editMeeting' : 'planMeeting')}
				</Typography>
			</Box>
			<form onSubmit={formik.handleSubmit}>
				<Grid spacing={1} container width={'100%'} paddingTop={2}>
					<Grid item xs={12} paddingBottom={2}>
						<Box>
							<Box sx={{
								fontWeight: 600,
								marginBottom: '10px',
								fontSize: '17px',
								paddingLeft: '4px',
								color: '#424242'
							}}>
								{t('meetingTitleRequired')}
							</Box>
							<TextField
								fullWidth={true}
								placeholder={t('meetingName')}
								variant="outlined"
								name={'name'}
								type={'text'}
								className={'border-gray'}
								value={formik.values.name || ''}
								onChange={formik.handleChange}
								error={formik.touched.name && Boolean(formik.errors.name)}
								helperText={formik.touched.name && formik.errors.name}
								FormHelperTextProps={{
									style: {
										backgroundColor: '#EFEFEF',
										padding: 0,
										margin: 0
									}
								}}
							/>
						</Box>
					</Grid>
					
					<Grid
						item xs={12}
						md={12}
						display={'flex'}
						justifyContent={'space-between'}
						alignItems={'center'}
						style={{marginTop: -18}}>
						{/* Sol Taraf: Tarih Yazısı */}
						<Box
							sx={{
								fontWeight: 600,
								fontSize: '17px',
								color: '#424242',
								paddingLeft: '4px'
							}}
						>
							{t('date')}
						</Box>
						
						{/* Sağ Taraf: Tekrar Eden Toplantı ve Switch */}
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: '8px'
							}}
						>
							<Box
								sx={{
									fontWeight: 600,
									fontSize: '17px',
									color: '#424242',
									paddingRight: '4px'
								}}
							>
								{t('recurringMeeting')}
							</Box>
							<FormControlLabel
								control={
									<Switch
										checked={!!isRecurring}
										onChange={(e) => {
											setIsRecurring(e.target.checked);
											if (!e.target.checked) setRecurrenceType('none'); // Kapatılırsa türü sıfırla
										}}
										color="primary"
									/>
								}
								labelPlacement="start"
								sx={{
									margin: 0
								}}
							/>
						
						</Box>
					</Grid>
					
					{isRecurring && (
						<>
							{/* Tekrar Periyodu: Günlük veya Haftalık */}
							<Grid item xs={12} paddingBottom={2}>
								<Box>
									<TextField
										fullWidth={true}
										select
										variant="outlined"
										name="recurrenceType"
										value={recurrenceType}
										onChange={(e) => setRecurrenceType(e.target.value)}
									>
										<MenuItem value="daily">{t('daily')}</MenuItem>
										<MenuItem value="weekly">{t('weekly')}</MenuItem>
									</TextField>
								</Box>
							</Grid>
							
							{/* Haftalık Tekrar Seçeneği: Gün Seçimi */}
							{recurrenceType === 'weekly' && (
								<Grid item xs={12} paddingBottom={2} style={{marginTop: -6}}>
									<Box>
										<Box
											sx={{
												fontWeight: 600,
												marginBottom: '10px',
												fontSize: '17px',
												paddingLeft: '4px',
												color: '#424242'
											}}
										>
											{t('selectDays')}
										</Box>
										<Autocomplete
											multiple
											options={[
												{label: t('sunday'), value: 0},
												{label: t('monday'), value: 1},
												{label: t('tuesday'), value: 2},
												{label: t('wednesday'), value: 3},
												{label: t('thursday'), value: 4},
												{label: t('friday'), value: 5},
												{label: t('saturday'), value: 6}
											]}
											getOptionLabel={(option) => option.label}
											value={selectedDays}
											onChange={(e, value) => {
												setSelectedDays(value);
												formik.setFieldValue('selectedDays', value.map(v => v.value));
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="outlined"
													placeholder={selectedDays.length === 0 ? t('selectDaysPlaceholder') : ''}
													InputLabelProps={{shrink: false}}
												/>
											)}
										/>
									</Box>
								</Grid>
							)}
						
						</>
					)}
					<Grid container spacing={2} style={{marginBottom: -10, marginTop: -5}}>
						{/* Başlangıç Tarihi ve Saati */}
						<Grid item xs={12} md={6} paddingBottom={2}>
							<Box display="flex" gap={1} alignItems="center">
								{!isRecurring && (
									<Box sx={{flex: 1}}>
										<DesktopDatePicker
											disablePast
											name="startingDate"
											inputFormat="dd/MM/yyyy"
											value={formik.values.startingDate}
											onChange={(value) => {
												formik.setFieldValue('startingDate', moment(value));
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													className="border-gray"
													onKeyDown={(e) => e.preventDefault()}
												/>
											)}
										/>
									</Box>
								)}
								<Box sx={{flex: 1}}>
									<TimeTextField
										id="time"
										type="time"
										name="startingDate"
										mindate={new Date()}
										value={moment(formik.values.startingDate).format('HH:mm')}
										InputLabelProps={{shrink: true}}
										inputProps={{step: 1800}}
										className="border-gray"
										onChange={(value) => {
											let startingDate = formik.values.startingDate;
											let hour = value.split(':')[0];
											let minutes = value.split(':')[1];
											let newDate = moment(startingDate)
												.set('hours', hour)
												.set('minutes', minutes);
											if (moment() < newDate) {
												formik.setFieldValue('startingDate', newDate);
											}
										}}
									/>
								</Box>
							</Box>
						</Grid>
						
						{/* Bitiş Tarihi ve Saati */}
						<Grid item xs={12} md={6} paddingBottom={2}>
							<Box display="flex" gap={1} alignItems="center">
								{!isRecurring && (
									<Box sx={{flex: 1}}>
										<DesktopDatePicker
											minDate={new Date(formik.values.startingDate)}
											disablePast
											name="endDate"
											inputFormat="dd/MM/yyyy"
											value={formik.values.endDate}
											onChange={(value) => {
												let endDate = formik.values.endDate;
												let startingDate = formik.values.startingDate;
												
												if (endDate > startingDate) {
													let newDate = moment(value)
														.set('hours', moment(startingDate).hour())
														.set('minutes', moment(startingDate).minutes() + 30);
													formik.setFieldValue('endDate', newDate);
												} else {
													formik.setFieldValue('endDate', moment(value));
												}
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													className="border-gray"
													onKeyDown={(e) => e.preventDefault()}
												/>
											)}
										/>
									</Box>
								)}
								<Box sx={{flex: 1}}>
									<TimeTextField
										id="time"
										type="time"
										name="endDate"
										mindate={moment(formik.values.endDate)}
										value={moment(formik.values.endDate).format('HH:mm')}
										InputLabelProps={{shrink: true}}
										inputProps={{step: 300}}
										className="border-gray"
										onChange={(value) => {
											let endDate = formik.values.endDate;
											let startingDate = formik.values.startingDate;
											let hour = value.split(':')[0];
											let minutes = value.split(':')[1];
											let newDate = moment(endDate)
												.set('hours', hour)
												.set('minutes', minutes);
											if (newDate > startingDate) {
												formik.setFieldValue('endDate', newDate);
											}
										}}
									/>
								</Box>
							</Box>
						</Grid>
					</Grid>
					
					<Grid item xs={12} paddingBottom={2}>
						<Box>
							<Box sx={{
								fontWeight: 600,
								marginBottom: '10px',
								fontSize: '17px',
								paddingLeft: '4px',
								color: '#424242'
							}}>
								{t('addParticipants')}
							</Box>
							<Autocomplete
								freeSolo
								multiple
								clearOnBlur
								handleHomeEndKeys
								options={addressBook}
								renderInput={(params) => (
									<TextField
										{...params}
										className={'border-gray'}
										name={'userIds'}
										placeholder={t('addParticipants')}
										variant="outlined"
										error={
											formik.touched.userIds && Boolean(formik.errors.userIds)
										}
										helperText={formik.touched.userIds && formik.errors.userIds}
									/>
								)}
								value={formik.values.userIds}
								onKeyDown={(e) => {
									if (e.keyCode === 13) {
										e.preventDefault();
									}
								}}
								getOptionLabel={(option) => {
									if (typeof option === 'string') {
										return option;
									}
									if (option.inputValue) {
										return option.inputValue;
									}
									return option.fullName || option.name;
								}}
								onChange={(e, values) => {
									if (values.length > 0) {
										const newItem = values[values.length - 1];
										if (typeof newItem === 'string') {
											// Yeni kullanıcı ekleme
											if (ValidateEmail(newItem)) {
												const newUser = {
													email: newItem,
													fullName: newItem,
													id: formik.values.userIds.length + 1
												};
												setAddressBook((prev) => [...prev, newUser]);
												formik.setFieldValue('userIds', [...values]);
											} else {
												// Geçersiz e-posta uyarısı
												formik.setFieldValue('userIds', values.slice(0, -1));
											}
										} else {
											// Geçerli kullanıcı ekleme
											formik.setFieldValue('userIds', values);
										}
									} else {
										// Kullanıcıların tümü silindiğinde
										formik.setFieldValue('userIds', values);
									}
								}}
								filterOptions={(options, params) => {
									const filtered = filter(options, params);
									if (params.inputValue !== '') {
										filtered.push({
											inputValue: params.inputValue,
											name: `${t('add')} "${params.inputValue}"`
										});
									}
									return filtered;
								}}
							/>
						</Box>
					</Grid>
					
					<Grid item container xs={12}>
						<Box display="flex" width={'100%'}>
							<LoadingButton
								loading={buttonIsLoading}
								size={'large'}
								variant={'contained'}
								type={'submit'}
								disabled={isButtonDisabled || buttonIsLoading}
								sx={{
									width: '100%',
									padding: '15px',
									background: 'linear-gradient(to right, #d32f2f, #f44336, #ff5252) !important',
									color: '#fff !important',
									'&.Mui-disabled': {
										background: 'linear-gradient(to right, #bdbdbd, #e0e0e0) !important',
										color: '#9e9e9e !important'
									}
								}}
							>
								{t('plan')}
							</LoadingButton>
						
						</Box>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

AddOrUpdateMeetingForm.propTypes = {
	setVisibleModal: PropTypes.func,
	getMeetings: PropTypes.func,
	userIds: PropTypes.array,
	meetingTitle: PropTypes.string,
	selectedMeeting: PropTypes.object
};

export default AddOrUpdateMeetingForm;
