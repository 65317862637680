import {
	CHANGE_AUTHENTICATION,
	SET_APPLICATION_INFORMATION_IS_LOADED,
	SET_CONTAINER_LOADER,
	SET_CURRENT_PRODUCT,
	SET_HUB_CONNECTION,
	SET_INCOMING_CALL,
	SET_IS_MOBILE,
	SET_ONLINE_USERS,
	SET_OUT_GOING_USER_NAME,
	SET_OUTGOING_CALL,
	SET_PACKAGES,
	SET_PAGE_SIZE,
	SET_PRIVILEGE_NAMES,
	SET_PRIVILEGES,
	SET_SELECTED_PACKAGE,
	SET_USER
} from './types';

export const changeAuthentication = (payload) => {
	return {
		type: CHANGE_AUTHENTICATION,
		payload
	};
};

export const setUser = (payload) => {
	return {
		type: SET_USER,
		payload
	};
};

export const setPrivileges = (payload) => {
	return {
		type: SET_PRIVILEGES,
		payload
	};
};

export const setApplicationInformationIsLoaded = (payload) => {
	return {
		type: SET_APPLICATION_INFORMATION_IS_LOADED,
		payload
	};
};

export const setPrivilegeNames = () => {
	return {
		type: SET_PRIVILEGE_NAMES
	};
};

export const setContainerLoader = (payload) => {
	return {
		type: SET_CONTAINER_LOADER,
		payload
	};
};

export const setCurrentProduct = (payload) => {
	return {
		type: SET_CURRENT_PRODUCT,
		payload
	};
};

export const setSelectedPackage = (payload) => {
	return {
		type: SET_SELECTED_PACKAGE,
		payload
	};
};

export const setPackages = (payload) => {
	return {
		type: SET_PACKAGES,
		payload
	};
};

export const setMobile = (payload) => {
	return {
		type: SET_IS_MOBILE,
		payload
	};
};

export const setPageSize = (payload) => {
	return {
		type: SET_PAGE_SIZE,
		payload
	};
};

export const setHubConnection = (payload) => {
	return {
		type: SET_HUB_CONNECTION,
		payload
	};
};

export const setIncomingCall = (payload) => {
	return {
		type: SET_INCOMING_CALL,
		payload
	};
};

export const setOutGoingUserName = (payload) => {
	return {
		type: SET_OUT_GOING_USER_NAME,
		payload
	};
};

export const setOutGoingCall = (payload) => {
	return {
		type: SET_OUTGOING_CALL,
		payload
	};
};

export const setOnlineUsers = (payload) => {
	return {
		type: SET_ONLINE_USERS,
		payload
	};
};
