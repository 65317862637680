import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import Calender from './components/Calender';
import Typography from '@mui/material/Typography';
import {MeetingServices, PricingServices} from '../../../../../services';
import {useTranslation} from 'react-i18next';
import store from '../../../../../redux/store';
import {setCurrentProduct} from '../../../../../redux/actions';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddOrUpdateMeetingForm from './components/AddOrUpdateMeetingForm';
import Modal from '@mui/material/Modal';
import MeetingForm from './components/MeetingForm';
import './components/styles.css';

function Meetings() {
	const {t} = useTranslation();
	const location = useLocation();
	const [meetings, setMeetings] = useState([]);
	const [calendarLoading, setCalendarLoading] = useState(false);
	const [createModalVisible, setCreateModalVisible] = useState(false);
	const [addOrUpdatePlanMeetingModal, setAddOrUpdatePlanMeetingModal] = useState(false);
	const [selectedMeeting, setSelectedMeeting] = useState(null);
	
	useEffect(() => {
		getMeetings();
		getCurrentProducts();
		setTimeout(() => {
			if (location.search.includes('createNow')) {
				setCreateModalVisible(true);
			}
		}, 500);
	}, []);
	
	function getMeetings() {
		setCalendarLoading(false);
		let promise = MeetingServices.getMeetings({
			'pageNumber': 1, 'rowCount': 100, 'sortDirection': 1, 'sortColumn': ''
		});
		
		promise.then(responsive => {
			if (responsive && responsive.data && responsive.data.result && responsive.data.result.items.length > 0) {
				let meetings = responsive.data.result.items;
				
				const meetingArr = meetings.map((item) => {
					
					let meeting = {
						calendarId: '1',
						category: 'time',
						isVisible: item.isActive,
						title: item.name,
						id: item.id,
						body: item.description,
						start: new Date(item.startingDate),
						end: new Date(item.endDate),
						token: item.meetingToken,
						meetingId: item.meetingId,
						meetingUsers: item.meetingUsers,
						creatorFullName: item.creatorFullName,
						creatorId: item.creatorId,
						meetingStatus: item.meetingStatus,
						recurrenceType: item.recurrenceType,
						selectedDays: item.selectedDays,
						parentId: item.parentId
					};
					
					return meeting;
				});
				setMeetings(meetingArr);
			}
			setCalendarLoading(true);
		});
	}
	
	function getCurrentProducts() {
		var promise = PricingServices.getCurrentProduct();
		promise.then(response => {
			store.dispatch(setCurrentProduct(response.data));
		});
	}
	
	return (
		<div>
			<Box marginBottom={{xs: 1, md: 5}}>
				<Typography
					variant="h5"
					color="text.tertiary"
					sx={{fontWeight: 700, textAlign: 'start'}}
				>
					{t('meetings')}
				</Typography>
			</Box>
			
			{calendarLoading && <Calender
				getMeetings={getMeetings}
				selectedMeeting={selectedMeeting}
				setSelectedMeeting={setSelectedMeeting}
				meetings={meetings}
				setEditModalVisible={setAddOrUpdatePlanMeetingModal}
				setCreateModalVisible={setCreateModalVisible}
				setPlanModalVisible={setAddOrUpdatePlanMeetingModal}
			/>}
			
			{/*Hızlı Toplantı Oluşturma */}
			<Modal
				onClose={() => setCreateModalVisible(false)}
				open={createModalVisible}
			>
				<Box style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					maxWidth: '551px',
					width: '100%',
					minHeight: '331px',
					backgroundColor: '#fff',
					border: '0px solid #000',
					borderRadius: '15px',
					boxShadow: 24,
					padding: '60px 20px 20px 20px',
					paddingBottom: 5,
					overflow: 'auto',
					'&::-webkit-scrollbar': {
						width: '0px'
					}
				}}>
					
					<a style={{
						backgroundColor: '#F0F5FF',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '100%',
						width: '34px',
						height: '34px',
						position: 'absolute', right: '20px', top: '20px'
					}} onClick={() => {
						setCreateModalVisible(false);
					}}>
						<CloseRoundedIcon/>
					</a>
					
					<Box style={{width: '100%', margin: '0 auto'}}>
						<MeetingForm
							getMeetings={getMeetings}
						/>
					</Box>
				</Box>
			</Modal>
			
			{/*Planlı Toplantı Oluşturma & Düzenleme*/}
			<Modal
				onClose={() => setAddOrUpdatePlanMeetingModal(false)}
				open={addOrUpdatePlanMeetingModal}
			>
				<Box className={'add-edit-modal'}>
					<a style={{
						backgroundColor: '#F0F5FF',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '100%',
						width: '34px',
						height: '34px',
						position: 'absolute', right: '20px', top: '20px'
					}} onClick={() => {
						setAddOrUpdatePlanMeetingModal(false);
					}}>
						<CloseRoundedIcon/>
					</a>
					<Box style={{maxWidth: '630px', margin: '0 auto'}}>
						<AddOrUpdateMeetingForm
							getMeetings={getMeetings}
							setVisibleModal={setAddOrUpdatePlanMeetingModal}
							selectedMeeting={selectedMeeting}
						/>
					</Box>
				</Box>
			</Modal>
		</div>);
}

export default Meetings;
