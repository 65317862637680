import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {useTranslation} from 'react-i18next';
import userServices from '../../../../../services/userServices';
import AddUserDialog from './AddUserDialog';
import EditUserDialog from './EditUserDialog';
import AddOrUpdateMeetingForm from '../Meetings/components/AddOrUpdateMeetingForm';
import moment from 'moment/moment';
import {MeetingServices} from '../../../../../services';
import Swal from 'sweetalert2';
import {useHistory} from 'react-router-dom';
import {Base64} from 'js-base64';
import {useSelector} from 'react-redux';
import BusinessIcon from '@mui/icons-material/Business'; // Firma ikonu

const Addressbook = () => {
    const {t, i18n} = useTranslation();
    const loginUser = useSelector((state) => state.main.user);
    const history = useHistory();
    const [addressBook, setAddressBook] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [userToEdit, setUserToEdit] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedUsersModel, setSelectedUsersModel] = useState([]);
    const [showButtons, setShowButtons] = useState(false);
    const [showPlanMeeting, setShowPlanMeeting] = useState(false);
    const [companyUsers, setCompanyUsers] = useState([]);

    useEffect(() => {
        const fetchAddressBook = async () => {
            try {
                const response = await userServices.getAddressbook();
                const updatedAddressBook = response.data.map(user => ({
                    ...user,
                    name: user.name || 'No Name',
                }));

                const companyUsersResponse = await userServices.getAllCompanyUsers();
                const updatedCompanyUsers = companyUsersResponse.data.map(user => ({
                    ...user,
                    name: user.fullName || 'No Name',
                }));

                setAddressBook([...updatedAddressBook, ...updatedCompanyUsers]);
                setCompanyUsers(updatedCompanyUsers); // Şirket kullanıcılarını state'e kaydediyoruz.
            } catch (error) {
                console.error('Error fetching address book or company users:', error);
            }
        };

        fetchAddressBook();
    }, []);


    const handleAddNewUser = async (newUser) => {
        try {
            // Şirket kullanıcılarını hariç tutarak sadece yeni kullanıcıyı ekliyoruz
            const updatedAddressBook = [...addressBook, newUser].filter(user =>
                !companyUsers.some(companyUser => companyUser.email === user.email)
            );

            // Adres defterini güncelle
            await userServices.addOrUpdateAddressbook(updatedAddressBook);
            setAddressBook([...updatedAddressBook, ...companyUsers]);
        } catch (error) {
            console.error('Error adding user:', error);
        }
    };

    const handleDeleteUser = async () => {
        try {
            // Silinen kullanıcıları adres defterinden çıkar
            const updatedAddressBook = addressBook.filter(user =>
                user.email !== userToDelete.email &&
                !companyUsers.some(companyUser => companyUser.email === user.email) // Şirket kullanıcılarını hariç tutuyoruz.
            );

            // Adres defterini güncelle
            await userServices.addOrUpdateAddressbook(updatedAddressBook);

            // Şirket kullanıcılarını da ekleyerek adres defterini güncelle
            setAddressBook([...updatedAddressBook, ...companyUsers]);

            // Silme işleminden sonra dialogu kapat ve seçili kullanıcıyı sıfırla
            setOpenDeleteDialog(false);
            setUserToDelete(null);
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };


    const handleEditUser = (user) => {
        setUserToEdit(user);
        setOpenEditDialog(true);
    };

    const handleSaveEditedUser = (editedUser) => {
        // Şirket kullanıcılarını filtrele ve yalnızca diğer kullanıcıları adres defterine ekle
        const filteredAddressBook = addressBook.filter(user =>
            !companyUsers.some(companyUser => companyUser.email === user.email)
        );

        // Düzenlenen kullanıcıyı güncelle
        const updatedAddressBook = filteredAddressBook.map((user) =>
            user.id === editedUser.id ? editedUser : user
        );

        // Güncellenmiş adres defterini sadece düzenlenmiş kullanıcılarla kaydet
        setAddressBook([...updatedAddressBook, ...companyUsers]);
        userServices.addOrUpdateAddressbook(updatedAddressBook);
    };


    const handleCheckboxChange = (id) => {
        setSelectedUsers((prevSelectedUsers) => {
            if (prevSelectedUsers.includes(id)) {
                return prevSelectedUsers.filter((userId) => userId !== id);
            } else {
                return [...prevSelectedUsers, id];
            }
        });
    };

    useEffect(() => {
        setShowButtons(selectedUsers.length > 0);
    }, [selectedUsers]);

    // `name` verisine göre sıralama
    const sortedAddressBook = [...addressBook].sort((a, b) => {
        return a.fullName.localeCompare(b.fullName);
    });

    // Arama filtresi hem `name` hem de `email` üzerinde çalışacak
    const filteredAddressBook = sortedAddressBook.filter((item) =>
        (item.fullName && item.fullName.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.email && item.email.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const createQuicklyMeeting = () => {
        const now = moment();
        const start = now.add(10, 'seconds').local().format();
        const end = now.add(1, 'hour').local().format();
        const userIds = selectedUsers.map(id => {
            const user = addressBook.find(user => user.id === id);
            if (user) {
                return {
                    email: user.email,
                    fullName: user.name,
                    id: user.id
                };
            }
            return null;
        }).filter(Boolean);

        let values = {
            name: t('fastConnection'),
            userIds: userIds,
            selectedDays: [],
            startingDate: start,
            endDate: end,
            description: '',
            freeJoin: true,
        };

        console.log(values);

        Swal.fire({
            title: t('loading'),
            text: t('creatingMeeting'),
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        let promise = MeetingServices.addOrUpdateMeeting(values);

        promise
            .then((response) => {
                if (response && response.data) {
                    Swal.fire({
                        icon: 'success',
                        title: t('success'),
                        text: t('successPlanMeeting'),
                        showCloseButton: false,
                        showConfirmButton: true,
                        showDenyButton: true,
                        confirmButtonText: t('ok'),
                        denyButtonText: t('joinMeeting'),
                    }).then((result) => {
                        if (result.isConfirmed) {
                            null;
                        } else if (result.isDenied) {
                            const meetingId = response.data.meetingId;
                            let hashedMeetinDetails = Base64.encode(`?meetingId=${meetingId}&userId=${loginUser.id}&userName=${loginUser.fullname}&email=${loginUser.email}`);
                            history.push(`/${i18n.language}/meeting/go/${hashedMeetinDetails}`);
                        }
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: t('error'),
                    text: t('errorCreatingMeeting'),
                    showCloseButton: true,
                    showConfirmButton: true,
                    confirmButtonText: t('ok'),
                });
            });
    };

    return (
        <Box>
            <Box marginBottom={7}>
                <Grid container>
                    <Grid item md={8} xs={12}>
                        <Typography
                            variant="h5"
                            color="text.tertiary"
                            sx={{
                                fontWeight: 700,
                                textAlign: 'start',
                            }}
                        >
                            {t('addressBook')}
                        </Typography>
                    </Grid>
                    <Grid item md={4} container sx={{justifyContent: 'end'}}>
                        <TextField
                            fullWidth
                            label={t('search')}
                            size="small"
                            sx={{width: {xs: '100%', md: 240}, mr: 1, mt: {xs: 3, md: 0}}}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid container sx={{marginTop: 2}}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIsDialogOpen(true)}
                    >
                        +
                    </Button>

                    {showButtons && (
                        <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2, width: '90%'}}>
                            <Button variant="contained" color="primary" onClick={() => createQuicklyMeeting()}>
                                {t('newMeeting')}
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => {
                                const userIds = selectedUsers.map(id => {
                                    const user = addressBook.find(user => user.id === id);
                                    if (user) {
                                        return {
                                            email: user.email,
                                            fullName: user.name,
                                            id: user.id
                                        };
                                    }
                                    return null;
                                }).filter(Boolean);
                                setSelectedUsersModel(userIds);
                                setShowPlanMeeting(true);
                            }}>
                                {t('planMeeting')}
                            </Button>
                        </Box>
                    )}
                </Grid>

                <Grid container sx={{marginTop: 5, borderTop: '1px solid rgba(58,21,161,50%)'}}>
                    {filteredAddressBook.map((item, i) => (
                        <Grid
                            key={i}
                            container
                            direction="row"
                            sx={{
                                paddingTop: 2,
                                paddingBottom: 2,
                                borderBottom: '1px solid rgba(58,21,161,50%)',
                            }}
                        >
                            <Grid item md={4} style={{display: 'flex', alignItems: 'center'}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedUsers.includes(item.id)}
                                            onChange={() => handleCheckboxChange(item.id)}
                                        />
                                    }
                                    label={item.fullName}
                                />
                            </Grid>
                            <Grid item md={4} style={{display: 'flex'}}>
                                <Box flex={1} component="div" marginLeft="10px" paddingTop="4px">
                                    <strong>{item.email}</strong>
                                </Box>
                            </Grid>
                            <Grid item md={4} textAlign="right">
                                {!companyUsers.some(companyUser => companyUser.email === item.email) ? (
                                    <>
                                        <IconButton onClick={() => handleEditUser(item)}>
                                            <EditIcon color="primary"/>
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                setUserToDelete(item);
                                                setOpenDeleteDialog(true);
                                            }}
                                        >
                                            <DeleteIcon color="error"/>
                                        </IconButton>
                                    </>
                                ) : (
                                    // Firma kullanıcıları için Business ikonu göster
                                    <BusinessIcon color="primary"/>
                                )}
                            </Grid>


                        </Grid>
                    ))}
                </Grid>
            </Box>

            <AddUserDialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                onSave={handleAddNewUser}
            />

            <EditUserDialog
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
                user={userToEdit}
                onSave={handleSaveEditedUser}
            />

            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                <DialogContent>{t('areYouSure')}</DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleDeleteUser} color="primary">
                        {t('areYouSureDeleteButton')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={showPlanMeeting} onClose={() => setShowPlanMeeting(false)}>
                <DialogContent>
                    <AddOrUpdateMeetingForm
                        userIds={selectedUsersModel}
                        open={showPlanMeeting}
                        onClose={() => setShowPlanMeeting(false)}
                        user={userToEdit}
                        onSave={handleDeleteUser}
                        setVisibleModal={setShowPlanMeeting}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default Addressbook;
