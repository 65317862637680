import React, {useEffect, useRef, useState} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import {Box, Button, Grid, Popover} from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import TUICalendar from '@toast-ui/react-calendar';
import PropTypes from 'prop-types';
import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import HeaderButtons from './HeaderButtons';
import ReactCardFlip from 'react-card-flip';
import GroupIcon from '@mui/icons-material/Group';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './styles.css';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {useSelector} from 'react-redux';
import TooltipButton from '../../../../../../layouts/Main/components/ButtonWithTooltip/TooltipButton';
import Swal from 'sweetalert2';
import {useTranslation} from 'react-i18next';
import {languageFormat} from '../../../../../../utils/language';
import {MeetingServices} from '../../../../../../services';
import {Base64} from 'js-base64';

const calendars = [{
	id: '1',
	name: 'Toplantı Takvimim',
	color: '#ffffff',
	bgColor: '#9e5fff',
	dragBgColor: '#9e5fff',
	borderColor: '#9e5fff'
}];

function CustomCalendar({
	meetings,
	setCreateModalVisible,
	setPlanModalVisible,
	setEditModalVisible,
	selectedMeeting,
	setSelectedMeeting,
	getMeetings
}) {
	const {t, i18n} = useTranslation();
	const theme = useTheme();
	const history = useHistory();
	const cal = useRef(null);
	// eslint-disable-next-line no-unused-vars
	const isMd = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true
	});
	const [modalPosition, setModalPosition] = React.useState('top');
	// eslint-disable-next-line no-unused-vars
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [anchorPosition, setAnchorPosition] = React.useState({
		left: 0, top: 0, bottom: 0, height: 0, right: 0, width: 0, x: 0, y: 0
	});
	const [katilimciOpen, setKatilimciOpen] = useState(false);
	const [calendarRendering, setCalendarRendering] = useState(true);
	const [detailPopupVisible, setDetailPopupVisible] = useState(false);
	const [calenderViewType, setCalenderViewType] = useState('month');
	const [calenderDate, setCalenderDate] = useState(new Date());
	const [rangeStart, setRangeStart] = useState(new Date());
	const [rangeEnd, setRangeEnd] = useState(new Date());
	const [isFlipped, setIsFlipped] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const loginUser = useSelector((state) => state.main.user);
	const [meetingHasFinished, setMeetingHasFinished] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [meetingNotStarted, setMeetingNotStarted] = useState(false);
	const [meetingCreatorIsMe, setMeetingCreatorIsMe] = useState(false);
	const [daynames, setDaynames] = useState([t('sunday'), t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday')]);
	const [dayShortnames, setShortDaynames] = useState([t('shortSunday'), t('shortMonday'), t('shortTuesday'), t('shortWednesday'), t('shortThursday'), t('shortFriday'), t('shortSaturday')]);
	
	useEffect(() => {
		setRangeStart(cal.current.calendarInst.getDateRangeStart());
		setRangeEnd(cal.current.calendarInst.getDateRangeEnd());
	}, [calenderViewType]);
	
	useEffect(() => {
		if (detailPopupVisible === false) setIsFlipped(false);
	}, [detailPopupVisible]);
	
	useEffect(() => {
		if (selectedMeeting) {
			setMeetingCreatorIsMe(loginUser.fullname === selectedMeeting.creatorFullName);
			setMeetingHasFinished(new Date() > selectedMeeting.end);
			setMeetingNotStarted(new Date() <= selectedMeeting.start);
		}
	}, [selectedMeeting]);
	
	useEffect(() => {
		setDaynames([t('sunday'), t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday')]);
		setShortDaynames([t('shortSunday'), t('shortMonday'), t('shortTuesday'), t('shortWednesday'), t('shortThursday'), t('shortFriday'), t('shortSaturday')]);
	}, [i18n.language]);
	
	useEffect(() => {
		setCalendarRendering(false);
		setTimeout(() => setCalendarRendering(true), 1);
	}, [daynames, dayShortnames]);
	
	function moveToNextOrPrevRange(val) {
		if (val === -1) {
			cal.current.calendarInst.prev();
		} else if (val === 1) {
			cal.current.calendarInst.next();
		}
		setCalenderDate(cal.current.calendarInst.getDate());
		setRangeStart(cal.current.calendarInst.getDateRangeStart());
		setRangeEnd(cal.current.calendarInst.getDateRangeEnd());
	}
	
	function updateDate(val) {
		let date = new Date(val);
		cal.current.calendarInst.setDate(date);
		setCalenderDate(date);
		setRangeStart(cal.current.calendarInst.getDateRangeStart());
		setRangeEnd(cal.current.calendarInst.getDateRangeEnd());
	}
	
	function showDetail(e) {
		setMeetingNotStarted(false);
		let meeting = meetings.filter(x => x.id == e.schedule.id)[0];
		setSelectedMeeting(meeting);
		
		let firstDate = new Date(cal.current.calendarInst.getDateRangeStart());
		let centerDate = new Date(firstDate.setDate(firstDate.getDate() + 20));
		let eventDate = new Date(e.schedule.start);
		let position = eventDate > centerDate ? 'bottom' : 'top';
		setModalPosition(position);
		setAnchorPosition(e.event.target.offsetParent.getBoundingClientRect());
		setDetailPopupVisible(true);
	}
	
	function joinMeeting() {
		if (new Date() < selectedMeeting.end && new Date() > selectedMeeting.start) {
			let meetingId = meetings.filter(x => x.id === selectedMeeting.id)[0].meetingId;
			let hashedMeetinDetails = Base64.encode(`?meetingId=${meetingId}&userId=${loginUser.id}&userName=${loginUser.fullname}&email=${loginUser.email}`);
			history.push(`/${i18n.language}/meeting/go/${hashedMeetinDetails}`);
		} else {
			setSelectedMeeting(null);
			setKatilimciOpen(false);
			setDetailPopupVisible(false);
			Swal.fire({
				icon: 'warning',
				title: t('warningPaymentCancellation'),
				text: t('meetingTimeError'),
				confirmButtonText: t('close')
			});
		}
	}
	
	function deleteMeeting() {
		
		Swal.fire({
			title: t('areYouSure'),
			text: t('cannotUndo'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#f44336',
			cancelButtonColor: '#3085d6',
			confirmButtonText: t('areYouSureDeleteButton'),
			cancelButtonText: t('cancel'),
			showLoaderOnConfirm: true,
			preConfirm: () => {
				return MeetingServices.suspendMeeting(selectedMeeting.meetingId).then(() => {
					getMeetings();
					return true;
				});
			},
			allowOutsideClick: false
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: t('deleted'), text: t('meetingHasDeleted'), icon: 'success', confirmButtonText: t('ok')
				});
			}
		});
	}
	
	return (<div>
		<HeaderButtons
			setCreateModalVisible={setCreateModalVisible}
			setPlanModalVisible={setPlanModalVisible}
			calenderViewType={calenderViewType}
			setCalenderViewType={setCalenderViewType}
			moveNextOrPrev={moveToNextOrPrevRange}
			calenderDate={calenderDate}
			setCalenderDate={updateDate}
			rangeStart={rangeStart}
			rangeEnd={rangeEnd}
		/>
		<Box style={{minHeight: '600px'}} sx={{
			marginLeft: {xs: calenderViewType !== 'month' ? '-60px' : 0, md: 0},
			marginRight: {xs: calenderViewType !== 'month' ? '-20px' : 0, md: 0}
		}}
		>
			{calendarRendering && <TUICalendar
				ref={cal}
				height="100%"
				key={meetings.length}
				view={calenderViewType}
				useCreationPopup={false}
				useDetailPopup={false}
				calendars={calendars}
				schedules={meetings}
				onClickSchedule={showDetail}
				isReadOnly={true}
				theme={{
					'week.currentTime.fontWeight': 'bold'
				}}
				taskView={false}
				scheduleView={['time']}
				timezone={{
					zones: [{
						timezoneName: 'Turkey', displayLabel: 'GMT+03:00', tooltip: 'Türkiye'
					}]
				}}
				template={{
					timegridDisplayPrimayTime: function (time) {
						return padLeft(time.hour) + ':' + padRight(time.minutes);
					}, monthGridHeaderExceed: (hiddenSchedules) => {
						return '<span class="tui-full-calendar-weekday-grid-more-schedules">+ ' + hiddenSchedules + '</span>';
					}
				}}
				month={isMd ? {
					daynames: daynames, startDayOfWeek: 1, narrowWeekend: false
				} : {
					daynames: dayShortnames, startDayOfWeek: 1, narrowWeekend: false
				}}
				week={isMd ? {
					daynames: daynames, startDayOfWeek: 1, narrowWeekend: false, timezonesCollapsed: true
				} : {
					daynames: ['', '', '', '', '', '', ''], startDayOfWeek: 1, narrowWeekend: false
				}}
			/>}
		</Box>
		
		<Popover
			open={detailPopupVisible}
			anchorPosition={anchorPosition}
			anchorReference={'anchorPosition'}
			anchorOrigin={{
				vertical: modalPosition, horizontal: 'right'
			}}
			onClose={() => {
				setSelectedMeeting(null);
				setKatilimciOpen(false);
				setDetailPopupVisible(false);
			}}
			transformOrigin={{
				vertical: modalPosition, horizontal: 'left'
			}}
		>
			<Box sx={{
				width: 350, height: 380, padding: '10px 10px 10px 25px', backgroundColor: '#fff'
			}}>
				<ReactCardFlip
					isFlipped={isFlipped}
					flipDirection="horizontal"
				>
					<Grid container spacing={2} direction="column">
						<Grid item>
							<div
								style={{
									fontWeight: 600, fontSize: 15, marginTop: 20, color: '#3F3F3F'
								}}
							>
								{selectedMeeting ? selectedMeeting.title : ''}
							</div>
						</Grid>
						<Grid item>
							<div style={{
								fontSize: 12, color: 'gray'
							}}>
								{selectedMeeting ? new Date(selectedMeeting.start).toLocaleDateString(languageFormat(i18n.language), {
									weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
								}) : ''}
							</div>
							<div style={{
								fontSize: 12, color: 'gray'
							}}>
								{selectedMeeting ? moment(selectedMeeting.start).format('HH:mm') + ' - ' + moment(selectedMeeting.end).format('HH:mm') : ''}</div>
						</Grid>
						<Grid item>
							<div
								style={{
									minHeight: 46, fontWeight: 600, fontSize: 13, marginTop: 4, color: '#3F3F3F'
								}}
							>
								{selectedMeeting ? selectedMeeting.body : ''}
							</div>
						</Grid>
						<Grid item>
							<div style={{justifyContent: 'right', height: 35, marginTop: 10, display: 'flex'}}>
								
								{meetingCreatorIsMe && <div>
									<TooltipButton
										tooltipText={meetingHasFinished ? t('meetingIsEnd') : t('meetingNotDeletig')}
										showTooltipWhenDisabled={true}
										disabled={meetingHasFinished || !meetingNotStarted}
										onClick={() => {
											deleteMeeting();
										}}
										variant="text"
										sx={{
											fontWeight: 600,
											backgroundColor: '#ffff',
											color: '#000',
											padding: '5px 15px',
											textDecorationLine: 'underline',
											border: 0,
											fontSize: 12
										}}
									>
										{t('meetingDelete')}
									</TooltipButton>
								</div>
								
								}
								{meetingCreatorIsMe && <div>
									<TooltipButton
										tooltipText={meetingHasFinished ? t('meetingIsEnd') : (selectedMeeting?.meetingStatus === 'MeetingStatusOngoing' ? t('meetingIsOnGoing') : null)}
										showTooltipWhenDisabled={true}
										disabled={meetingHasFinished || !meetingNotStarted || selectedMeeting?.meetingStatus === 'MeetingStatusOngoing'}
										onClick={() => {
											setEditModalVisible(true);
										}}
										variant="text"
										sx={{
											fontWeight: 600,
											backgroundColor: '#ffff',
											color: '#000',
											padding: '5px 15px',
											textDecorationLine: 'underline',
											border: 0,
											fontSize: 12
										}}
									>
										{t('edit')}
									</TooltipButton>
								</div>
								
								}
								
								<TooltipButton
									disabled={meetingHasFinished || meetingNotStarted}
									showTooltipWhenDisabled={true}
									tooltipText={meetingHasFinished ? t('meetingIsEndJoin') : meetingNotStarted ? 'Toplantı Henüz Başlamadı' : null}
									onClick={joinMeeting}
									variant="contained"
									sx={{
										fontWeight: 600, color: '#fff', padding: '5px 15px', borderRadius: 2, border: 0, fontSize: 12
									}}
								>
									{t('joinMeeting')}
								</TooltipButton>
							</div>
						</Grid>
						<Grid item>
							<div
								style={{
									marginRight: -10,
									marginLeft: -10,
									marginTop: 0,
									marginBottom: 0,
									backgroundColor: '#F2F2F2',
									height: 1
								}}
							/>
						</Grid>
						<Grid item sx={{marginTop: -4}}>
							<div
								style={{fontSize: 12, fontWeight: 600, color: '#3F3f3F', display: 'block', marginTop: '26px'}}
							>
								{t('created')}
							</div>
						</Grid>
						<Grid item sx={{marginTop: -1}}>
							<div
								style={{fontSize: 12, fontWeight: 500, color: '#3F3f3F', display: 'flex'}}
							>
								<div
									style={{
										width: 10, height: 10, backgroundColor: '#A186EC', borderRadius: 10, marginTop: 3, marginRight: 4
									}}
								/>
								{selectedMeeting ? selectedMeeting.creatorFullName : ''}
							</div>
						</Grid>
						<Grid item>
							<div
								style={{
									marginRight: -10,
									marginLeft: -10,
									marginTop: 0,
									marginBottom: 12,
									backgroundColor: '#F2F2F2',
									height: 1
								}}
							/>
						</Grid>
						<Button
							startIcon={<GroupIcon/>}
							onClick={() => setIsFlipped(true)}
							variant="outlined"
							sx={{
								fontWeight: 600, padding: '5px 15px', textDecorationLine: 'underline', fontSize: 12
							}}
						>
							{t('participants')}
						</Button>
					</Grid>
					
					<Grid
						container
						spacing={2}
						style={{
							width: 340
						}}
						sx={{
							width: '100%', padding: '10px', bgcolor: 'background.paper'
						}}
					>
						<List
							style={{width: 'calc(100% - 7px)', height: '287px'}}
							component="nav"
							aria-labelledby="nested-list-subheader"
						>
							<ListItemButton
								onClick={() => setKatilimciOpen(!katilimciOpen)}
								sx={{display: 'flex'}}
							>
								<div style={{fontSize: 12, fontWeight: 600, color: '#3F3f3F', marginLeft: -16}}
								>
									{t('participants')}
								</div>
							</ListItemButton>
							<div
								style={{
									width: '100%', marginBottom: 10, backgroundColor: '#F2F2F2', height: 1
								}}
							/>
							<List
								component="div"
								disablePadding
								sx={{
									position: 'static !important', overflow: 'auto', height: 170, width: '%100'
								}}
							>
								{selectedMeeting && selectedMeeting.meetingUsers ? selectedMeeting.meetingUsers.map((item, i) => {
									return (<div
										key={i}
										style={{fontSize: 12, fontWeight: 500, color: '#3F3f3F', display: 'flex', marginBottom: 4}}>
										<div
											style={{
												width: 10,
												height: 10,
												backgroundColor: '#CCBAFF',
												borderRadius: 10,
												marginTop: 3,
												marginRight: 4
											}}
										/>
										{item.fullName}
									</div>);
								}) : ''}
							
							</List>
						</List>
						
						<Button
							startIcon={<ArrowBackIcon/>}
							fullWidth
							onClick={() => setIsFlipped(false)}
							variant="outlined"
							sx={{
								fontWeight: 600, fontSize: 12, mr: 1, mt: 1, mb: 1
							}}
						>
							{t('back')}
						</Button>
					</Grid>
				</ReactCardFlip>
			</Box>
		</Popover>
	</div>);
}

const padLeft = (char) => {
	if (char.toString().length == 1) {
		return '0' + char;
	} else {
		return char;
	}
};

const padRight = (char) => {
	if (char.toString().length == 1) {
		return char + '0';
	} else {
		return char;
	}
};

CustomCalendar.propTypes = {
	meetings: PropTypes.array,
	setCreateModalVisible: PropTypes.func,
	setPlanModalVisible: PropTypes.func,
	setEditModalVisible: PropTypes.func,
	setSelectedMeeting: PropTypes.func,
	getMeetings: PropTypes.func,
	selectedMeeting: PropTypes.object
};

export default CustomCalendar;
